
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from "vue-class-component";

import { FormatDateTime } from '@/helpers/dateHelper';
import { IPayload } from '@/helpers/promiseHelper';
import { ForumThreadViewModel } from "@/apiClient";

import Spinner from './Spinner.vue';

class TheFishyForumThreadsProps {
  data: IPayload<ForumThreadViewModel[]> | null = null;
}

@Options({
  components: {
    FontAwesomeIcon,
    Spinner
  }
})
export default class TheFishyForumThreads extends Vue.with(TheFishyForumThreadsProps) {
  public date(thread: ForumThreadViewModel): string {
    return FormatDateTime(thread.lastReplyAt);
  }
}


import { Options, Vue } from "vue-class-component";

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Headlines from "./components/Headlines.vue";
import LeagueTable from "./components/LeagueTable.vue";
import NextMatchRemainingSeats from "./components/NextMatchRemainingSeats.vue";
import TheFishyForumThreads from "./components/TheFishyForumThreads.vue";
import UpcomingFixtures from "./components/UpcomingFixtures.vue";
import TodaysFixtures from "./components/TodaysFixtures.vue";
import Videos from "./components/Videos.vue";
import { HandlePromise, IPayload } from "./helpers/promiseHelper";
import Spinner from "./components/Spinner.vue";

import {
  AllTownArentWeApiClient,
  HeadlineViewModel,
  LeagueTableViewModel,
  ForumThreadViewModel,
  FixtureViewModel,
  MatchSeatCountViewModel,
  VideoViewModel
} from "./apiClient";
import { Config } from "./config";

@Options({
  components: {
    Header,
    Footer,
    Spinner,
    Headlines,
    LeagueTable,
    UpcomingFixtures,
    TodaysFixtures,
    NextMatchRemainingSeats,
    TheFishyForumThreads,
    Videos: Videos
  },
})
export default class App extends Vue {
  private readonly apiClient: AllTownArentWeApiClient = new AllTownArentWeApiClient(Config.apiUrl);

  public readonly Tab = Tab;
  public selectedTab: Tab = Tab.All;

  public headlines: IPayload<HeadlineViewModel[]> | null = null;
  public leagueTable: IPayload<LeagueTableViewModel> | null = null;
  public nextMatchRemainingSeatCount: IPayload<MatchSeatCountViewModel> | null = null;
  public theFishyForumThreads: IPayload<ForumThreadViewModel[]> | null = null;
  public todaysFixtures: IPayload<FixtureViewModel[]> | null = null;
  public upcomingFixtures: IPayload<FixtureViewModel[]> | null = null;
  public videos: IPayload<VideoViewModel[]> | null = null;

  public async mounted(): Promise<void> {
    const [headlines, leagueTable, nextMatchRemainingSeatCount, theFishyForumThreads, todaysFixtures, upcomingFixtures, videos] =
        await Promise.allSettled([
          this.apiClient.headlines(),
          this.apiClient.leagueTable(),
          this.apiClient.nextMatchRemainingSeatCount(),
          this.apiClient.theFishyForumThreads(),
          this.apiClient.today(),
          this.apiClient.upcoming(),
          this.apiClient.videos()
        ]);

    this.headlines = HandlePromise(headlines);
    this.leagueTable = HandlePromise(leagueTable);
    this.nextMatchRemainingSeatCount = HandlePromise(nextMatchRemainingSeatCount);
    this.theFishyForumThreads = HandlePromise(theFishyForumThreads);
    this.todaysFixtures = HandlePromise(todaysFixtures);
    this.upcomingFixtures = HandlePromise(upcomingFixtures);
    this.videos = HandlePromise(videos);

    window.addEventListener('resize', this.handleResize);
  }

  public unmounted(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  private handleResize(): void {
    if (window.innerWidth >= 992) { // lg breakpoint
      this.selectedTab = Tab.All;
    }
  }

  public getVisibleTabs(): Tab[] {
    return [
      Tab.All,
      Tab.LeagueTable,
      this.nextMatchRemainingSeatCount?.data ? Tab.NextMatchRemainingSeats : null,
      Tab.TheFishyForumThreads,
      Tab.Fixtures,
      Tab.Videos
    ].filter(t => t !== null) as Tab[];
  }

  public selectTab(tab: Tab): void {
    this.selectedTab = tab;
  }

  public tabVisible(tab: Tab): boolean {
    return this.selectedTab == Tab.All || this.selectedTab === tab;
  }
}

enum Tab {
  All = "All",
  LeagueTable = "League Table",
  Fixtures = "Fixtures",
  TheFishyForumThreads = "Fishy Threads",
  NextMatchRemainingSeats = "Seats",
  Videos = "Videos",
}
